// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-effect-glassmor-js": () => import("./../../../src/pages/effect/glassmor.js" /* webpackChunkName: "component---src-pages-effect-glassmor-js" */),
  "component---src-pages-effect-index-js": () => import("./../../../src/pages/effect/index.js" /* webpackChunkName: "component---src-pages-effect-index-js" */),
  "component---src-pages-effect-neumor-js": () => import("./../../../src/pages/effect/neumor.js" /* webpackChunkName: "component---src-pages-effect-neumor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-center-js": () => import("./../../../src/pages/layout/center.js" /* webpackChunkName: "component---src-pages-layout-center-js" */),
  "component---src-pages-layout-footer-js": () => import("./../../../src/pages/layout/footer.js" /* webpackChunkName: "component---src-pages-layout-footer-js" */),
  "component---src-pages-layout-header-js": () => import("./../../../src/pages/layout/header.js" /* webpackChunkName: "component---src-pages-layout-header-js" */),
  "component---src-pages-layout-hero-js": () => import("./../../../src/pages/layout/hero.js" /* webpackChunkName: "component---src-pages-layout-hero-js" */),
  "component---src-pages-layout-index-js": () => import("./../../../src/pages/layout/index.js" /* webpackChunkName: "component---src-pages-layout-index-js" */),
  "component---src-pages-layout-section-js": () => import("./../../../src/pages/layout/section.js" /* webpackChunkName: "component---src-pages-layout-section-js" */),
  "component---src-pages-licence-js": () => import("./../../../src/pages/licence.js" /* webpackChunkName: "component---src-pages-licence-js" */),
  "component---src-pages-overview-about-js": () => import("./../../../src/pages/overview/about.js" /* webpackChunkName: "component---src-pages-overview-about-js" */),
  "component---src-pages-overview-colors-js": () => import("./../../../src/pages/overview/colors.js" /* webpackChunkName: "component---src-pages-overview-colors-js" */),
  "component---src-pages-overview-index-js": () => import("./../../../src/pages/overview/index.js" /* webpackChunkName: "component---src-pages-overview-index-js" */),
  "component---src-pages-overview-start-js": () => import("./../../../src/pages/overview/start.js" /* webpackChunkName: "component---src-pages-overview-start-js" */),
  "component---src-pages-overview-text-js": () => import("./../../../src/pages/overview/text.js" /* webpackChunkName: "component---src-pages-overview-text-js" */),
  "component---src-pages-parts-button-js": () => import("./../../../src/pages/parts/button.js" /* webpackChunkName: "component---src-pages-parts-button-js" */),
  "component---src-pages-parts-card-js": () => import("./../../../src/pages/parts/card.js" /* webpackChunkName: "component---src-pages-parts-card-js" */),
  "component---src-pages-parts-dropdown-js": () => import("./../../../src/pages/parts/dropdown.js" /* webpackChunkName: "component---src-pages-parts-dropdown-js" */),
  "component---src-pages-parts-fab-js": () => import("./../../../src/pages/parts/fab.js" /* webpackChunkName: "component---src-pages-parts-fab-js" */),
  "component---src-pages-parts-file-js": () => import("./../../../src/pages/parts/file.js" /* webpackChunkName: "component---src-pages-parts-file-js" */),
  "component---src-pages-parts-index-js": () => import("./../../../src/pages/parts/index.js" /* webpackChunkName: "component---src-pages-parts-index-js" */),
  "component---src-pages-parts-input-js": () => import("./../../../src/pages/parts/input.js" /* webpackChunkName: "component---src-pages-parts-input-js" */),
  "component---src-pages-parts-modal-visual-js": () => import("./../../../src/pages/parts/modal-visual.js" /* webpackChunkName: "component---src-pages-parts-modal-visual-js" */),
  "component---src-pages-parts-normal-modal-js": () => import("./../../../src/pages/parts/normal-modal.js" /* webpackChunkName: "component---src-pages-parts-normal-modal-js" */),
  "component---src-pages-parts-separated-box-js": () => import("./../../../src/pages/parts/separated-box.js" /* webpackChunkName: "component---src-pages-parts-separated-box-js" */),
  "component---src-pages-parts-table-js": () => import("./../../../src/pages/parts/table.js" /* webpackChunkName: "component---src-pages-parts-table-js" */),
  "component---src-pages-parts-textarea-js": () => import("./../../../src/pages/parts/textarea.js" /* webpackChunkName: "component---src-pages-parts-textarea-js" */)
}

